@import "base";

.login {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  form {
    text-align: center;

    .logo {
      margin-bottom: 30px;
      margin-inline-end: auto;
      display: block;
    }

    padding-inline: 10px;
    min-width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 500px) {
      min-width: 100%;
    }

    a {
      color: $color-link;
      text-decoration: underline;
    }

    .MuiButton-containedPrimary.Mui-disabled {
      background-color: #3600c7aa;
    }
  }
}
