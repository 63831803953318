@import "src/static/styles/base";

.app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .content {
    background-color: $color-bg;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
